import UserProfile from 'views/admin/UserProfile';
import EditProfile from 'views/admin/UserProfile/EditProfile';
import NotFound from 'views/admin/NotFound';
import Plugs from 'views/admin/Plugs';
import PlugInfo from 'views/admin/PlugInfo';
import { Meta } from 'views/admin/Metas';

var routes = [
  {
    path: '/pluginfo/:idPlug',
    invisible: true,
    component: PlugInfo,
    layout: '/admin',
  },
  {
    path: '/user-profile',
    invisible: true,
    component: UserProfile,
    layout: '/admin',
  },
  {
    path: '/edit-profile',
    invisible: true,
    component: EditProfile,
    layout: '/admin',
  },
  {
    path: '/home',
    name: 'Fichas',
    icon: 'fas fa-ticket-alt',
    component: Plugs,
    layout: '/admin',
  },
  {
    path: '/metas',
    name: 'Metas',
    icon: 'fas fa-crosshairs',
    component: Meta,
    layout: '/admin',
  },
  {
    path: '*',
    invisible: true,
    component: NotFound,
    layout: '/admin',
  },
];
export default routes;
