import React from 'react';
import ReactDatetime from 'react-datetime';

import './styles.scss';

import {
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from 'reactstrap';

export default function Datepicker({ value, onChange, placeholder }) {
  return (
    <>
      <FormGroup className="dateComponent">
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-calendar-grid-58" />
            </InputGroupText>
          </InputGroupAddon>
          <ReactDatetime
            inputProps={{
              placeholder,
            }}
            timeFormat={false}
            dateFormat="DD/MM/YYYY"
            onChange={onChange}
            value={value}
          />
        </InputGroup>
      </FormGroup>
    </>
  );
}
