import React from "react";

import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";

import Header from "components/Header";
import Loading from 'components/Loading';

export default function Page({ children, title, classNameCSS, loading }) {
  return (
    <>
      {loading && <Loading />}
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="bg-gradient-secondary shadow">
              <CardHeader className="bg-transparent">
                <h4>{title}</h4>
              </CardHeader>
              <CardBody className={classNameCSS}>{children}</CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
