import React, { useRef, Component } from 'react';
import ReactToPrint from 'react-to-print';
import moment from 'moment';

import { Modal, Row, Col, Button } from 'reactstrap';

import './styles.scss';

class ComponentToPrint extends Component {
  render() {
    return (
      <section className="printPlug">
        <p>
          <span className="mr-3">Ficha nº {this.props.plug.idPlug}</span>
          <span>### Ficha de Produção ###</span>
          <span className="ml-3">
            Produto nº {this.props.plug.nuvemShopProductId}
          </span>
        </p>
        <div class="infoPlug">
          <img
            src={this.props.plug.urlImageProduct}
            alt="Imagem do produto final"
          />
          <div>
            <span>{this.props.plug.name}</span>
            <br />
            <span>Etapa:</span> {this.props.plug.Stage.name}
            <br />
            <span>Responsável:</span> {this.props.actualPlugState.User.name}
            <br />
            <span>Quantidade:</span> {this.props.totalPairs}
            <br />
            <span>Data do pedido:</span>{' '}
            {moment(this.props.plug.VariationPlugs[0].orderDate).format(
              'DD/MM/YYYY'
            )}
            <br />
            <span>Data da externa:</span>{' '}
            {moment(this.props.actualPlugState.createdAt).format('DD/MM/YYYY')}
            <br />
            <span>Descrição:</span>
            <br />
            {this.props.descs[0].description
              ? this.props.descs.map((desc, index) => (
                  <>
                    <span>Item {index + 1}: </span>
                    {desc.description}
                    <br />
                  </>
                ))
              : ''}
            <br />
            <table>
              <tr>
                <th>Números</th>
                {this.props.pairs ? (
                  this.props.pairs.map((pair) => (
                    <th key={pair.idVariationPlug}>{pair.variationValue}</th>
                  ))
                ) : (
                  <th>-</th>
                )}
              </tr>
              <tr>
                <th>Pares</th>
                {this.props.pairs ? (
                  this.props.pairs.map((pair) => (
                    <td key={pair.idVariationPlug}>{pair.quantity}</td>
                  ))
                ) : (
                  <td>-</td>
                )}
              </tr>
            </table>
          </div>
        </div>
      </section>
    );
  }
}

export default function ModalPrint({
  isOpen,
  toggle,
  plug,
  pairs,
  descs,
  totalPairs,
  actualPlugState,
}) {
  const componentRef = useRef();
  return (
    <>
      <Modal
        className="modal-dialog-centered modal-lg"
        isOpen={isOpen}
        toggle={() => toggle()}
      >
        <div className="modal-header">
          <h5 className="modal-title">Imprimir etapa da ficha</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggle()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Row className="printModal">
            <ComponentToPrint
              plug={plug}
              pairs={pairs}
              totalPairs={totalPairs}
              actualPlugState={actualPlugState}
              descs={descs}
              ref={componentRef}
            />
          </Row>
          <Row className="mt-4 float-right">
            <Col>
              <ReactToPrint
                trigger={() => <Button color="primary">Imprimir</Button>}
                content={() => componentRef.current}
              />
            </Col>
            <Col>
              <Button color="danger" onClick={toggle}>
                Fechar
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}
