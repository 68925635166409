import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import moment from 'moment';
import swalError from 'common/swalError';

import { useHistory, useParams } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Button,
} from 'reactstrap';

import Header from 'components/Header';
import ModalPrint from './ModalPrint';
import Loading from 'components/Loading';
import DatePicker from 'components/DatePicker';
import api from 'config/api';
import './styles.scss';

const PlugContext = React.createContext([{}, () => {}]);

export default function PlugInfo() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [printModal, setPrintModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isHide, setIsHide] = useState(false);

  const { idPlug } = useParams();
  const [plug, setPlug] = useState({});
  const [userList, setUserList] = useState({});
  const [actualPlugState, setActualPlugState] = useState();
  const [actualPlugStateId, setActualPlugStateId] = useState();

  //Status da Ficha
  const [plugState, setPlugState] = useState('Pendente');
  const [stateColor, setStateColor] = useState('text-muted');
  const [stateIcon, setStateIcon] = useState('fa-exclamation-circle');
  const [actualUserId, setActualUserId] = useState('');
  const [actualUserName, setActualUserName] = useState('');
  const [pairs, setPairs] = useState([]);
  const [actualStage, setActualStage] = useState({});
  const [isPrinted, setIsPrinted] = useState(false);
  const [descs, setDescs] = useState();

  //Dados para enviar
  const [idUser, setIdUser] = useState();
  const [entryDate, setEntryDate] = useState();
  const [shippingDate, setShippingDate] = useState();

  //Defeitos
  const [defects, setDefects] = useState([]);
  const [variationValue, setvariationValue] = useState();
  const [quantity, setquantity] = useState();

  useEffect(() => {
    infoFetch();
    userFetch();
  }, []);

  // Requisições na API

  async function infoFetch() {
    setLoading(true);
    try {
      const { data } = await api.get(`/admin/plug/${idPlug}`);
      setPlug(data.data);
      setActualStage(data.data.Stage);
      setIsPrinted(data.data.isPrinted);
      setDescs(data.data.VariationPlugs);
      variationFetch();
      if (data.data.Stage.idStage === 1 || data.data.Stage.idStage === 5) {
        setIsHide(true);
      }
      if (data.data.UserPlugs.length > 0) {
        // Pega estado e datas
        const dataUserPlug = data.data.UserPlugs.slice(-1).pop();
        setActualPlugState(dataUserPlug);
        setActualPlugStateId(dataUserPlug.idUserPlug);
        setEntryDate(dataUserPlug.entryDate);
        setShippingDate(dataUserPlug.shippingDate);
        setActualUserName(dataUserPlug.User.name);
        setActualUserId(dataUserPlug.User.idUser);
        setPlugState('Em andamento');
        setStateColor('text-success');
      } else if (data.data.idStage === 5) {
        setPlugState('');
        setStateColor('text-primary');
        setStateIcon('fa-check-circle');
      }
    } catch (error) {
      swalError(history, error);
    }
    setLoading(false);
  }

  async function variationFetch() {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/supervisor/plug/${idPlug}/variationPlug`
      );
      setPairs(data.data);
    } catch (error) {
      swalError(history, error);
    }
    setLoading(false);
  }

  async function userFetch() {
    setLoading(true);
    try {
      const { data } = await api.get(`/supervisor/user`);
      setUserList(data.data);
    } catch (error) {
      swalError(history, error);
    }
    setLoading(false);
  }

  // Envio para API

  // // Iniciar ficha

  async function initializeStep() {
    setLoading(true);
    if (actualStage.idStage !== 1) {
      Swal.fire('Atenção!', 'Essa ficha não pode ser inicialziada', 'warning');
      setLoading(false);
      return;
    }

    try {
      await api.post(`/admin/plug/${idPlug}/nextStage`);

      Swal.fire(
        'Sucesso!',
        'O estágio da ficha foi inicializado.',
        'success'
      ).then(() => {
        window.location.reload();
      });
    } catch (error) {
      swalError(history, error);
    }
    setLoading(false);
  }

  // // Iniciar Etapa

  async function startStep() {
    setLoading(true);
    if (!actualPlugState) {
      if (!entryDate || !idUser || idUser === 0) {
        Swal.fire(
          'Atenção!',
          'Alguns campos não foram preenchidos, verifique!',
          'warning'
        );
        setLoading(false);
        return;
      }
    } else if (!entryDate || !shippingDate || !idUser || idUser === 0) {
      Swal.fire(
        'Atenção!',
        'Alguns campos não foram preenchidos, verifique!',
        'warning'
      );
      setLoading(false);
      return;
    } else if ((actualStage.idStage === 1) | (actualStage.idStage === 5)) {
      Swal.fire('Atenção!', 'Essa ficha não pode ser alterada', 'warning');
      setLoading(false);
      return;
    }
    try {
      await api.post(`/admin/userPlug`, {
        idPlug,
        idUser,
        entryDate,
      });
      Swal.fire('Sucesso!', 'A ficha foi atualizada!', 'success').then(() => {
        window.location.reload();
      });
    } catch (error) {
      swalError(history, error);
    }
    setLoading(false);
  }

  // // Próxima Etapa

  async function nextStep() {
    setLoading(true);
    if (!shippingDate) {
      Swal.fire(
        'Atenção!',
        'Alguns campos não foram preenchidos, verifique!',
        'warning'
      );
      setLoading(false);
      return;
    } else if ((actualStage.idStage === 1) | (actualStage.idStage === 5)) {
      Swal.fire('Atenção!', 'Essa ficha não pode ser alterada', 'warning');
      setLoading(false);
      return;
    } else if (isPrinted === false) {
      Swal.fire(
        'Atenção!',
        'Você deve imprimir a ficha antes de avançar',
        'warning'
      );
      setLoading(false);
      return;
    }

    try {
      if (defects.length > 0) {
        await api.post(
          `/admin/userPlug/${actualPlugStateId}/userPlugDefect`,
          defects
        );
      }
      await api.put(`/admin/userPlug/${actualPlugStateId}`, {
        shippingDate,
      });
      await api.post(`/admin/plug/${idPlug}/nextStage`);

      Swal.fire(
        'Sucesso!',
        'O estágio da ficha foi finalizado.',
        'success'
      ).then(() => {
        window.location.reload();
      });
    } catch (error) {
      swalError(history, error);
    }
    setLoading(false);
  }

  // Conferir defeitos duplicados

  function duplicateDefects(num, array) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].variationValue === num) {
        return true;
      }
    }
  }

  // Conferir se o defeito não ultrapassa a quantidade existente

  function quantityDefect(variationValue, quantity, array) {
    const variationDefect = array.find(
      (item) => item.variationValue.toString() === variationValue.toString()
    );
    return variationDefect.quantity < quantity;
  }

  // Reinserir dados (Deletar userPlug atual)

  function reinsertData() {
    Swal.fire({
      title: 'Deletar e reinserir',
      text:
        'Ao reiniciar a etapa você deletará definitivamente os dados inseridos anteriormente nesta etapa. Tem certeza que quer continuar?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Tenho certeza',
    }).then(async (res) => {
      if (res.value) {
        setLoading(true);
        try {
          await api.delete(`/admin/userPlug/${actualPlugStateId}`);
          Swal.fire(
            'Sucesso',
            'Dados deletados com sucesso. Você já pode inseri-los novamente',
            'success'
          ).then(() => {
            window.location.reload();
          });
        } catch (error) {
          swalError(history, error);
        }
        setLoading(false);
      }
    });
  }

  // Soma quantidade de pares

  function pairsSum(array) {
    const quantityList = array.map((x) => x.quantity);
    var result = 0;
    if (quantityList.length) {
      result = quantityList.reduce((total, quantity) => total + quantity);
    }
    return result;
  }

  //Defeitos

  function toggleDefect() {
    setIsOpen(!isOpen);
  }

  function addDefect() {
    if (quantity > pairs.quantity) {
      Swal.fire(
        'Atenção!',
        'Não é possível inserir mais pares com defeitos do que os pares existentes',
        'warning'
      );
      return;
    } else if (variationValue > 0 && quantity > 0) {
      if (quantityDefect(variationValue, quantity, pairs)) {
        Swal.fire(
          'Atenção!',
          'Não é possível inserir mais pares do que o existente',
          'warning'
        );
        return;
      } else if (duplicateDefects(variationValue, defects)) {
        Swal.fire(
          'Atenção!',
          'Não é possível inserir o mesmo número duas vezes',
          'warning'
        );
        return;
      }
      const variationDefect = {
        variationValue: variationValue,
        quantity: quantity,
      };
      setDefects([...defects, variationDefect]);
    } else {
      Swal.fire(
        'Atenção!',
        'Todos os campos devem ser preenchidos, verifique!',
        'warning'
      );
      return;
    }
  }

  function deleteDefect(index) {
    const variationDefect = defects.filter((item, i) => i !== index);
    setDefects(variationDefect);
  }

  // Abrir Fechar modal de impressão
  function togglePrintModal() {
    setIsPrinted(true);
    setPrintModal(!printModal);
  }

  return (
    <>
      {loading && <Loading />}
      <PlugContext.Provider
        value={{
          printModal,
          setPrintModal,
        }}
      />
      <ModalPrint
        isOpen={printModal}
        toggle={() => setPrintModal(!printModal)}
        plug={plug}
        pairs={pairs}
        descs={descs}
        totalPairs={pairsSum(pairs)}
        actualPlugState={actualPlugState}
      />
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card>
              <CardHeader className="bg-transparent">
                {/* ID do produto na nuvemshop */}
                <h1>Informações da ficha nº {idPlug}</h1>
                <button
                  className="button-like-link"
                  onClick={() => history.goBack()}
                >
                  <i className="fa fa-arrow-left" /> Voltar
                </button>
                {entryDate && actualPlugState && (
                  <button
                    className="button-like-link text-danger float-right"
                    onClick={() => reinsertData()}
                  >
                    <i className="fas fa-trash-alt" /> Reiniciar dados
                  </button>
                )}
              </CardHeader>
              <CardBody>
                <Row className="plugInfoRow">
                  <Col>
                    {/* Imagem ilustrativa do produto final */}
                    <img
                      src={plug && plug.urlImageProduct}
                      alt="Imagem do produto final da ficha"
                    />
                  </Col>
                  <Col>
                    <Row>
                      {/* Nome da ficha */}
                      <h2>{plug && plug.name}</h2>
                    </Row>
                    <Row>
                      <Label className={stateColor}>
                        {/* Nome do estágio atual e seu status */}
                        <span className="boldGp text-body mr-1">
                          {actualStage.name}:
                        </span>
                        {plugState}
                        <i className={`fas ${stateIcon} ml-1`}></i>
                      </Label>
                    </Row>
                    {isHide === false && (
                      <>
                        {pairsSum(pairs) > 0 && (
                          <Row>
                            {/* Listagem de usuários que podem ser designados ou de usuário responsável pela ficha */}
                            {actualUserId > 0 ? (
                              <Label>
                                <span className="boldGp mr-1">Externa:</span>{' '}
                                {actualUserName}
                              </Label>
                            ) : (
                              <FormGroup>
                                <Label
                                  for="externChoose mr-1"
                                  className="boldGp"
                                >
                                  Externa:{' '}
                                </Label>
                                <select
                                  className="form-control"
                                  id="externChoose"
                                  onChange={(e) => {
                                    setIdUser(parseInt(e.target.value));
                                  }}
                                >
                                  <option disabled>{actualStage.name}</option>
                                  <option value={0}>Escolha a banca</option>
                                  {userList && userList.length > 0 ? (
                                    // eslint-disable-next-line
                                    userList.map((user) => {
                                      if (
                                        user.idStage === actualStage.idStage
                                      ) {
                                        return (
                                          <option
                                            key={user.idUser}
                                            value={user.idUser}
                                          >
                                            {user.name} ({user.UserPlugs.length}
                                            )
                                          </option>
                                        );
                                      }
                                    })
                                  ) : (
                                    <option disabled>Sem opções</option>
                                  )}
                                </select>
                              </FormGroup>
                            )}
                          </Row>
                        )}
                      </>
                    )}
                    {/* Qtde de pares totais da ficha */}
                    <Row>
                      <Label>
                        <span className="boldGp mr-1">Pares:</span>
                        {pairsSum(pairs)}
                      </Label>
                    </Row>
                    {/* Tabela com todos os números(variações) e os pares de cada uma */}
                    <Row>
                      <table>
                        <thead>
                          <tr>
                            <th className="TableTh d-none d-md-table">
                              <span className="boldGp">Números: </span>
                            </th>
                            {pairs ? (
                              pairs.map((pair) => (
                                <th
                                  className="TableTh"
                                  key={pair.variationValue}
                                >
                                  <span
                                    key={pair.variationValue}
                                    className="ml-1 badge badge-pill numberBadge badge-primary"
                                  >
                                    {pair.variationValue}
                                  </span>
                                </th>
                              ))
                            ) : (
                              <th className="TableTh">{!loading ? '-' : ''}</th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th className="boldGp d-none d-md-table">Pares:</th>
                            {pairs ? (
                              pairs.map((pair) => (
                                <td
                                  className="tdNumberPlugInfo"
                                  key={pair.quantity}
                                >
                                  {pair.quantity}
                                </td>
                              ))
                            ) : (
                              <td className="tdNumberPlugInfo">
                                {!loading ? '-' : ''}
                              </td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </Row>
                    {/* Descrição */}
                    {descs ? (
                      <Row>
                        <div className="col-8 desc-wrapper">
                          <div className="boldGp mr-1 col-12">Descrição:</div>
                          {descs[0].description
                            ? descs.map((desc, index) => (
                                <div className="col-12 mt-1">
                                  <span className="boldGp">
                                    Item {index + 1}:{' '}
                                  </span>
                                  {desc.description}
                                </div>
                              ))
                            : ''}
                        </div>
                      </Row>
                    ) : (
                      ''
                    )}
                  </Col>
                  {/* data de entrada e saída */}
                </Row>
                {isHide === false && (
                  <>
                    {pairsSum(pairs) > 0 && (
                      <>
                        <hr />
                        <Row className="plugInfoRowCenter">
                          <Col xl={4}>
                            {entryDate && actualPlugState ? (
                              <Label>
                                <span className="boldGp mr-1">
                                  Data de início:
                                </span>{' '}
                                {moment(entryDate).format('DD/MM/YYYY')}
                              </Label>
                            ) : (
                              <DatePicker
                                placeholder="Data de início"
                                value={
                                  entryDate &&
                                  moment(entryDate).format('DD/MM/YYYY')
                                }
                                onChange={(e) => setEntryDate(e._d)}
                              />
                            )}
                          </Col>
                          <Col xl={4}>
                            {actualPlugState && (
                              <DatePicker
                                placeholder="Data de entrega"
                                value={
                                  shippingDate &&
                                  moment(shippingDate).format('DD/MM/YYYY')
                                }
                                onChange={(e) => setShippingDate(e._d)}
                              />
                            )}
                          </Col>
                          {/* Possíveis defeitos da ficha, entrada do número onde está 
                  o defeito e da quantidade de pares */}
                        </Row>
                        {actualPlugState && (
                          <>
                            <Row className="mt-3">
                              <Col>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="defectivePlug"
                                    onChange={toggleDefect}
                                  />
                                  <Label for="defectivePlug">
                                    Ficha com defeito
                                  </Label>
                                </div>
                              </Col>
                            </Row>
                            {isOpen && (
                              <>
                                <Row className="plugInfoRowCenter">
                                  <Col className="mb-2" xl={3}>
                                    <div className="input-group input-group-sm">
                                      <div className="input-group-prepend">
                                        <span
                                          className="input-group-text paddingPx bgWarningInputGroup"
                                          id="inputGroup-sizing-sm"
                                        >
                                          Número:
                                        </span>
                                      </div>
                                      <select
                                        className="form-control pl-3"
                                        id="defectChoose"
                                        onChange={(e) => {
                                          setvariationValue(e.target.value);
                                        }}
                                      >
                                        <option value={0}>
                                          Escolha o número
                                        </option>
                                        {pairs && pairs.length > 0 ? (
                                          pairs.map((pair) => (
                                            <option
                                              key={pair.variationValue}
                                              value={pair.variationValue}
                                            >
                                              {pair.variationValue}
                                            </option>
                                          ))
                                        ) : (
                                          <option disabled>Sem opções</option>
                                        )}
                                      </select>
                                    </div>
                                  </Col>
                                  <Col className="mb-2" xl={3}>
                                    <div className="input-group input-group-sm">
                                      <div className="input-group-prepend">
                                        <span
                                          className="input-group-text bgWarningInputGroup"
                                          id="inputGroup-sizing-sm"
                                        >
                                          Qtd pares:
                                        </span>
                                      </div>
                                      <input
                                        type="number"
                                        className="form-control pl-3"
                                        aria-label="Small"
                                        onChange={(e) =>
                                          setquantity(e.target.value)
                                        }
                                      />
                                    </div>
                                  </Col>
                                  <Col className="mb-2 plugInfoCol">
                                    <Button
                                      className="btn-success defectButton"
                                      onClick={() => addDefect()}
                                    >
                                      <i className="fas fa-plus-circle"></i>
                                    </Button>
                                  </Col>
                                </Row>
                                {defects ? (
                                  defects.map((defect, index) => (
                                    <Row
                                      className="plugInfoRowCenter mt-2"
                                      key={index}
                                    >
                                      <Col xl={3}>
                                        <span className="boldGp mr-1">
                                          Número:
                                        </span>
                                        {defect.variationValue}
                                      </Col>
                                      <Col xl={3}>
                                        <span className="boldGp mr-1">
                                          Quantidade:
                                        </span>
                                        {defect.quantity}
                                      </Col>
                                      <Col
                                        xl={1}
                                        className="deleteButton"
                                        onClick={() => deleteDefect(index)}
                                      >
                                        <i className="fas fa-ban"></i>
                                      </Col>
                                    </Row>
                                  ))
                                ) : (
                                  <span className="boldGp mr-1"></span>
                                )}
                              </>
                            )}
                          </>
                        )}
                        <Row className="mt-4 float-right">
                          <Col>
                            {!actualPlugState ? (
                              <Button
                                color="primary"
                                onClick={() => startStep()}
                              >
                                Iniciar etapa
                              </Button>
                            ) : (
                              <>
                                <Button
                                  color="secondary"
                                  onClick={() => togglePrintModal()}
                                >
                                  {isPrinted === true ? (
                                    <i className="fas fa-check-circle text-success mr-1"></i>
                                  ) : (
                                    <i className="fas fa-print mr-1"></i>
                                  )}
                                  Imprimir etapa
                                </Button>
                                <Button
                                  color="primary"
                                  onClick={() => nextStep()}
                                >
                                  Próxima etapa
                                </Button>
                              </>
                            )}
                          </Col>
                        </Row>
                      </>
                    )}
                  </>
                )}
                {actualStage.idStage === 1 && (
                  <Row className="mt-4 float-right">
                    <Col>
                      <Button color="primary" onClick={() => initializeStep()}>
                        Iniciar etapa
                      </Button>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
