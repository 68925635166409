import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import swalError from 'common/swalError';

import { Card, CardHeader, CardBody, Label, Row } from 'reactstrap';
import ModalHistory from '../ModalHistory';
import Loading from 'components/Loading';
import api from 'config/api';
import './styles.scss';

const PlugContext = React.createContext([{}, () => {}]);

export default function PlugCard({ plug }) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [actualPlugState, setActualPlugState] = useState();
  const [pairs, setPairs] = useState();
  const [variations, setVariations] = useState([]);
  //Status da Ficha
  const [plugState, setPlugState] = useState('Pendente');
  const [stateColor, setStateColor] = useState('text-muted');
  const [stateIcon, setStateIcon] = useState('fa-exclamation-circle');

  useEffect(() => {
    plugFetch();
    variationFetch();
  }, []);

  async function plugFetch() {
    setLoading(true);
    try {
      const { data } = await api.get(`/admin/plug/${plug.idPlug}`);
      setVariations(data.data.VariationPlugs);
      if (data.data.UserPlugs.length > 0) {
        // Pega estado e datas
        const dataUserPlug = data.data.UserPlugs.slice(-1).pop();
        setActualPlugState(dataUserPlug);
        setPlugState('Em andamento');
        setStateColor('text-success');
      } else if (data.data.idStage === 5) {
        setPlugState('Finalizado');
        setStateColor('text-primary');
        setStateIcon('fa-check-circle');
      }
    } catch (error) {
      swalError(history, error);
    }
    setLoading(false);
  }

  async function variationFetch() {
    try {
      const { data } = await api.get(
        `/supervisor/plug/${plug.idPlug}/variationPlug`
      );
      setPairs(data.data);
    } catch (error) {
      swalError(history, error);
    }
  }

  function toggleHistoryModal() {
    setHistoryModal(!historyModal);
  }

  function infoPlug(idPlug) {
    history.push(`/admin/pluginfo/${idPlug}`);
  }

  function pairsSum(array) {
    const quantityList = array.map((x) => x.quantity);
    var result = 0;
    if (quantityList.length) {
      result = quantityList.reduce((total, quantity) => total + quantity);
    }
    return result;
  }

  return (
    <>
      {loading && <Loading />}
      <PlugContext.Provider
        value={{
          historyModal,
          setHistoryModal,
        }}
      />
      <ModalHistory
        isOpen={historyModal}
        toggle={() => setHistoryModal(!historyModal)}
        plug={plug}
        variations={variations}
      />
      <Card className={`bg-gradient-white shadow mb-3 cardPlug col-lg-4 mx-1`}>
        <CardHeader className="pb-0">
          <Row className="mt-3 cardPlugRow">
            <Label>{plug.idPlug}</Label>
            <Label className="namePlug">{plug.name}</Label>
          </Row>
          <Label className={`plugStatus ${stateColor}`}>
            <i className={`fas ${stateIcon} mr-1`}></i>
            {plugState}
          </Label>
          <Label
            className="plugEdit text-primary"
            onClick={() => infoPlug(plug.idPlug)}
          >
            <i className="far fa-edit"></i>
          </Label>
        </CardHeader>
        <CardBody className="cardBodyPlug">
          <Label
            className="plugHistory text-primary"
            onClick={() => plug && toggleHistoryModal(plug.idPlug)}
          >
            <i className="fas fa-history"></i>
          </Label>
          <Row>
            <Label className="cardBodyLabel">
              <span className="boldGp">Banca:</span>{' '}
              {actualPlugState ? actualPlugState.User.name : '-'}
            </Label>
          </Row>
          <Row>
            <Label className="cardBodyLabel">
              <span className="boldGp">Pares:</span> {pairs && pairsSum(pairs)}
            </Label>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
