import React from 'react';
import { ClipLoader } from 'react-spinners';
import { Modal } from 'reactstrap';

import './styles.scss';

export default function Loading() {
  return (
    <Modal className="loading-modal" isOpen={true}>
      <ClipLoader size={60} color="#0b1e2a" />
    </Modal>
  );
}
