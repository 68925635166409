import React, { useEffect, useState, useContext } from 'react';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { MetaContext } from '../index';

import { Card, CardBody } from 'reactstrap';

import api from 'config/api';

export default function MetaForMonth() {
  const { setLoading } = useContext(MetaContext);
  const history = useHistory();
  const [meta, setMeta] = useState(0);
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  const initialDate = new Date(year, month, 1, 0, 0, 0);
  const finishedDate = new Date(year, month + 1, 1, 23, 59, 59);

  useEffect(() => {
    fetchCountFinishedVariations();
  }, []);

  async function fetchCountFinishedVariations() {
    setLoading(true);
    try {
      const { data } = await api.get(`/admin/userPlug/countFinishedVariations`);

      setMeta(data.data);
    } catch (error) {
      if (error.response.status === 401) {
        Swal.fire(
          'Token inválido',
          'Por favor, efetue login novamente',
          'info'
        ).then(() => {
          localStorage.clear();
          history.push('/');
        });
      } else {
        Swal.fire(
          'Erro',
          error.response.data.message ||
            'Ocorreu um erro, verifique os dados e tente novamente!',
          'error'
        );
      }
    }
    setLoading(false);
  }

  return (
    <Card className="mt-5 w-50 mx-auto text-center">
      <CardBody>
        <h5 className="card-title">Meta</h5>
        <p className="card-text">
          <span className="font-weight-bold">{meta}</span> de 1000
        </p>
        <p className="card-text">
          <span className="font-weight-bold">
            {moment(initialDate).format('DD/MM/YYYY')}
          </span>
          {' até '}
          <span className="font-weight-bold">
            {moment(finishedDate).format('DD/MM/YYYY')}
          </span>
        </p>
      </CardBody>
    </Card>
  );
}
