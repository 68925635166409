import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap';

import Header from 'components/Header';
import './styles.scss';

export default function NotFound() {
  const history = useHistory();

  return (
    <>
      <Header />
      <Container className="mt--7 reports-container" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0 mx-auto" xl="8">
            <Card className="bg-gradient-secondary shadow not-found-card">
              <CardHeader className="bg-transparent d-flex justify-content-center">
                <img
                  alt="logo"
                  className="thumb-desk"
                  src={require('assets/img/brand/logo.png')}
                />
              </CardHeader>
              <CardBody>
                <h1> 404 Página não foi encontrada</h1>
                <p>
                  Ops..., não foi possível encontrar essa página, click no botão
                  abaixo para voltar ao início
                </p>
                <Row className="float-right mr-3">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={() => history.push('/admin/home')}
                  >
                    Voltar
                  </Button>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
