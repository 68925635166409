import React, { useState } from 'react';

import Page from 'components/Page';

import VariationsForStage from './VariationsForStage';
import MetaForMonth from './MetaForMonth';

const MetaContext = React.createContext([{}, () => {}]);

function Meta() {
  const [loading, setLoading] = useState(false);

  return (
    <MetaContext.Provider
      value={{
        loading,
        setLoading,
      }}
    >
      <Page title="Metas" loading={loading}>
        <VariationsForStage></VariationsForStage>
        <MetaForMonth></MetaForMonth>
      </Page>
    </MetaContext.Provider>
  );
}

export { MetaContext, Meta };
