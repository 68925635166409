import React, { useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

import { MetaContext } from '../index';

import { Row, Col, Card, CardBody } from 'reactstrap';

import api from 'config/api';
import './styles.scss';

export default function VariationsForStage() {
  const { setLoading } = useContext(MetaContext);
  const [stages, setStages] = useState([]);
  const history = useHistory();

  useEffect(() => {
    fetchPlugsForStages();
  }, []);

  async function fetchPlugsForStages() {
    setLoading(true);
    try {
      const { data } = await api.get(`/admin/stage/plugsForStage`);

      setStages(data.data);
    } catch (error) {
      if (error.response.status === 401) {
        Swal.fire(
          'Token inválido',
          'Por favor, efetue login novamente',
          'info'
        ).then(() => {
          localStorage.clear();
          history.push('/');
        });
      } else {
        Swal.fire(
          'Erro',
          error.response.data.message ||
            'Ocorreu um erro, verifique os dados e tente novamente!',
          'error'
        );
      }
    }
    setLoading(false);
  }

  return (
    <>
      <Row className="text-center justify-content-center">
        {stages &&
          stages.map((stage) => (
            <Col key={stage.name} sm={2}>
              <Card className="h-100">
                <CardBody className="card-stage-text">
                  <h5 className="card-title">{stage.name}</h5>
                  <p className="card-text">{stage.quantityPlugs}</p>
                </CardBody>
              </Card>
            </Col>
          ))}
      </Row>
    </>
  );
}
