import React, { useEffect, useState } from 'react';

import { Modal, Row, Table, Col, Button } from 'reactstrap';

export default function ModalHistory({ isOpen, toggle, plug, variations }) {
  const [userPlugs, setUserPlugs] = useState([]);

  useEffect(() => {
    catchUserPlugs();
  });

  function catchUserPlugs() {
    const userPlugSort = plug.UserPlugs;
    userPlugSort.sort((a, b) => (a.User.idStage > b.User.idStage ? 1 : -1));
    setUserPlugs(userPlugSort);
  }

  function totalSum(vet) {
    const quantityList = vet.map((x) => x.quantity);
    var result = 0;
    if (quantityList.length)
      result = quantityList.reduce((total, quantity) => total + quantity);
    return result;
  }

  function subtractDefect(userPlug, sumActualStage) {
    const { idStage } = userPlug.User.Stage;
    const userPlugDefectList = [];

    userPlugs.forEach((item) => {
      if (
        (item.User.Stage.idStage <= idStage && sumActualStage) ||
        (item.User.Stage.idStage < idStage && !sumActualStage)
      ) {
        userPlugDefectList.push(...item.UserPlugDefects);
      }
    });

    return totalSum(userPlugDefectList);
  }

  return (
    <>
      <Modal
        className="modal-dialog-centered modal-lg"
        isOpen={isOpen}
        toggle={() => toggle()}
      >
        <div className="modal-header">
          <h5 className="modal-title">Histórico da ficha</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggle()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Table className="d-none d-md-table text-center" responsive striped>
              <thead>
                <tr>
                  <th className="TableTh">Etapa</th>
                  <th className="TableTh">Banca</th>
                  <th className="TableTh">Pares Entregues</th>
                  <th className="TableTh">Pares Defeituosos</th>
                  <th className="TableTh">Total</th>
                </tr>
              </thead>
              <tbody>
                {userPlugs ? (
                  userPlugs.map((userPlug) => (
                    <tr key={userPlug.idPlug}>
                      <th className="TableTd">{userPlug.User.Stage.name}</th>
                      <td className="TableTd">{userPlug.User.name}</td>
                      <td className="TableTd">
                        {variations &&
                          totalSum(variations) - subtractDefect(userPlug, true)}
                      </td>
                      <td className="TableTd">
                        {totalSum(userPlug.UserPlugDefects)}
                      </td>
                      <td className="TableTd">
                        {variations &&
                          totalSum(variations) -
                            subtractDefect(userPlug, false)}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="text-center" colSpan="8">
                      Nenhum registro foi encontrado...
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <ul style={{ listStyleType: 'none' }} className="d-md-none">
              <li>
                {userPlugs ? (
                  userPlugs.map((userPlug) => (
                    <div key={userPlug.idPlug}>
                      <Row>
                        <Col>
                          <span className="boldGp mr-2">Etapa:</span>
                          {userPlug.User.Stage.name}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <span className="boldGp mr-2">Banca:</span>
                          {userPlug.User.name}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <span className="boldGp mr-2">Recebido:</span>
                          {variations &&
                            totalSum(variations) -
                              subtractDefect(userPlug, true)}
                        </Col>
                        <Col>
                          <span className="boldGp mr-2">Defeito:</span>
                          {totalSum(userPlug.UserPlugDefects)}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <span className="boldGp mr-2">Total: </span>
                          {variations &&
                            totalSum(variations) -
                              subtractDefect(userPlug, false)}
                        </Col>
                      </Row>
                      <hr />
                    </div>
                  ))
                ) : (
                  <Row>
                    <span className="boldGp mr-2">
                      Nenhum registro encontrado
                    </span>
                  </Row>
                )}
              </li>
            </ul>
          </Row>
          <Row className="mt-4 float-right">
            <Col>
              <Button color="danger" onClick={toggle}>
                Fechar
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}
